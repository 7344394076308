var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MediaComponent',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('BaseImage',{staticClass:"is-64x64",attrs:{"is-rounded":"","src":_vm.profileData.icon_url}},[_c('img',{attrs:{"src":require("@/assets/img/undraw/profile_pic.svg")}})])]},proxy:true},{key:"right",fn:function(){return [_vm._t("right")]},proxy:true}],null,true)},[_c('div',[_c('p',{staticClass:"font-bold text-2xl",domProps:{"textContent":_vm._s(_vm.profileData.name)}}),_c('p',[_c('I18nRouterLink',{staticClass:"text-xl",attrs:{"to":{
          name: 'profile',
          params: { VANITY: _vm.profileData.vanity },
        }},domProps:{"textContent":_vm._s('@'.concat(_vm.profileData.vanity))}}),(_vm.profileData.slots.is_open)?[_vm._v(" • "),_c('span',{domProps:{"textContent":_vm._s(_vm.$tc(
            'slots.open',
            _vm.profileData.slots.available,
            { n: _vm.profileData.slots.available }
          ))}})]:[_vm._v(" • "),_c('span',{domProps:{"textContent":_vm._s(_vm.$t('slots.closed'))}})],(_vm.profileData.waitlist)?[(!_vm.profileData.waitlist.is_open)?[_vm._v(" • "),_c('span',{domProps:{"textContent":_vm._s(_vm.$tc(
              'waitlist.closed',
              _vm.profileData.slots.available,
              { n: _vm.profileData.slots.available }
            ))}})]:(_vm.profileData.waitlist.length === undefined)?[_vm._v(" • "),_c('span',{domProps:{"textContent":_vm._s(_vm.$tc(
              'waitlist.open',
              _vm.profileData.slots.available,
              { n: _vm.profileData.slots.available }
            ))}})]:[_vm._v(" • "),_c('span',{domProps:{"textContent":_vm._s(_vm.$tc(
              'waitlist.waiting',
              _vm.profileData.waitlist.length,
              { n: _vm.profileData.waitlist.length }
            ))}})]]:_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }