

















































































import { Profile } from '@/types/resource';
import Vue from 'vue';

export default Vue.extend({
  props: {
    profileData: {
      type: Object as () => Profile,
      required: true,
      validator: (d: any) => d.object === 'profile',
    },
  },
});
