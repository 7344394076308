











































































































































// TODO Split into new/Search & new/Request
import Vue from 'vue';
import { Profile, RequestedCommission, WaitingCommission } from '@/types/resource';
import RequestForm from '@/components/profile/RequestForm.vue';
import { mapGetters } from 'vuex';
import MakerSearch from '@/components/MakerSearch.vue';
import MakerSearchResult from '@/components/media/MakerSearchResult.vue';
import MakerSearchResultLarge from '@/components/media/MakerSearchResultLarge.vue';

export default Vue.extend({
  components: {
    MakerSearch,
    MakerSearchResult,
    MakerSearchResultLarge,
    RequestForm,
  },
  data() {
    return {
      vanity: null as string | null,
      isLocked: !!this.$route.query.maker,
      results: [] as Profile[],
      resultsEmpty: false,
      resultExactMatch: null as Profile | null,
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'account/IsLoggedIn',
      findByVanity: 'profiles/FindByVanity',
      previouslyCommissioned: 'profiles/PreviouslyCommissioned',
    }),
    profile(): Profile | undefined {
      if (!this.vanity) return undefined;
      return this.findByVanity(this.vanity);
    },
    previousProfiles(): Profile[] {
      return this.previouslyCommissioned.filter(
        // eslint-disable-next-line camelcase
        (p: Profile) => p.slots.is_open || p.waitlist?.is_open,
      );
      // .slice(0, Math.max(2, 6 - this.results.length));
    },
  },
  watch: {
    '$route.query.maker': {
      immediate: true,
      handler(to) {
        this.vanity = to || null;
      },
    },
    vanity: {
      immediate: true,
      handler(vanity) {
        if (vanity && this.findByVanity(vanity)) return;
        if (vanity) this.fetchByVanity(vanity);
      },
    },
    isAuthenticated: {
      immediate: true,
      handler(isAuth: boolean) {
        // Fetch immediately once logged in
        if (isAuth) this.fetchPreviouslyCommissioned();
      },
    },
  },
  methods: {
    select(vanity: string) {
      this.vanity = vanity;
    },
    redirect(commission: RequestedCommission | WaitingCommission) {
      this.$router.push({
        name: 'commission',
        params: { COMMISSION_ID: commission.id },
      });
    },
    async fetchByVanity(vanity: string) {
      let notFound = false;

      const data = await this.$store.dispatch('profiles/FindByVanity', vanity)
        .catch(() => {
          notFound = true;
        });

      const actualVanity = data?.data?.vanity;

      if (!notFound && actualVanity !== vanity) {
        this.vanity = actualVanity;
      }
    },
    onSearchResults(profiles: Profile[]) {
      this.results = profiles;
      this.resultsEmpty = profiles.length === 0;
    },
    onSearchExactMatch(profile: Profile | null) {
      this.resultExactMatch = profile;
    },
    async fetchPreviouslyCommissioned(): Promise<void> {
      if (!this.isAuthenticated) return;

      await this.$store.dispatch('profiles/ListCommissioned', {}).catch(console.warn);
    },
  },
  meta() {
    return {
      title: this.$t('titleChunk').toString(),
    };
  },
});
