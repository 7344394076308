








































import { CollectionResponse, Profile } from '@/types/resource';
import Vue from 'vue';
import { validateComponent, validationErrorHandler } from '@/validation';
import {
  rule, maxLength, minLength, length, required,
} from '@/validation/rules';
import { Validatable } from '@/validation/types';

export default Vue.extend({
  data() {
    return {
      error: null as Error | null,
      name: '',
      loading: false,
    };
  },
  computed: {
    nameField(): Validatable {
      return this.$refs.name as Validatable;
    },
    formComponents(): Record<string, Validatable> {
      return {
        name: this.nameField,
      };
    },
  },
  methods: {
    check(): boolean {
      return validateComponent(this.nameField, this.name, [
        required(this.$t('V.name.required')),
        minLength(3, this.$t('V.name.minlength')),
        maxLength(35, this.$t('V.name.maxlength')),
        length(3, 35, this.$t('V.name.length')),
        rule(
          (v) => !v.includes('%') && !v.includes('_'),
          this.$t('V.name.containsAny'),
        ),
      ]);
    },
    async submit(): Promise<void> {
      if (!this.check()) return;

      const search = this.name;

      this.loading = true;
      this.error = null;

      const res: CollectionResponse<Profile> = await this.$store
        .dispatch('profiles/Search', search)
        .catch(validationErrorHandler(this.formComponents, this.$t))
        .catch((e) => { this.error = e; });

      this.loading = false;
      if (!res) return;

      const results = res.data;
      let exactMatch: Profile | null = null;

      for (let i = 0; i < res.data.length; i += 1) {
        const profile = res.data[i];
        if (profile.vanity.toLowerCase() === search.toLowerCase()) {
          [exactMatch] = results.splice(i, 1);
          break;
        }
      }

      this.$emit('search-results', results);
      this.$emit('search-exact-match', exactMatch);
    },
  },
});
